import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'; // Import social media icons

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-8 border-t border-gray-600 dark:border-t-amber-50 dark:bg-gray-900">
            <div className="flex md:flex-row flex-col w-full items-center md:items-start justify-center gap-8 text-center md:text-left p-4">
                {/* Company Info */}
                <div>
                    <h3 className="text-xl font-bold">Oğuzhan Havalandırma</h3>
                    <p className="mt-4">
                        Endüstriyel ve ticari havalandırma sistemleri imalatı ve montajında uzmanlaşmış bir firmayız. Kaliteli ve güvenilir çözümler sunuyoruz.
                    </p>
                </div>

                {/* Quick Links */}
                <div>
                    <h3 className="text-xl font-bold">Hızlı Erişim</h3>
                    <ul className="mt-4 space-y-2">
                        <li><a href="#home" className="hover:text-blue-400">Ana Sayfa</a></li>
                        <li><a href="#about" className="hover:text-blue-400">Hakkımızda</a></li>
                        <li><a href="#services" className="hover:text-blue-400">Hizmetler</a></li>
                        <li><a href="#contact" className="hover:text-blue-400">İletişim</a></li>
                    </ul>
                </div>

                {/* Social Media */}
                <div>
                    <h3 className="text-xl font-bold">Bizi Takip Edin</h3>
                    <div className="flex justify-center md:justify-start space-x-4 mt-4">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"
                           className="hover:text-blue-600">
                            <FaFacebook size={24}/>
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"
                           className="hover:text-pink-600">
                            <FaInstagram size={24}/>
                        </a>
                        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"
                           className="hover:text-blue-500">
                            <FaLinkedin size={24}/>
                        </a>
                    </div>
                </div>
            </div>

            {/* Copyright Notice */}
            <div className="mt-8 border-t border-gray-600 pt-4 text-center">
                <p>&copy; 2024 Oğuzhan Havalandırma. Tüm hakları saklıdır.</p>
            </div>
        </footer>
    );
};

export default Footer;
