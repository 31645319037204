import { useState } from 'react';

const images = [
    '/images/1.jpg',
    '/images/2.jpg',
    '/images/3.jpg',
    '/images/4.jpg',
    '/images/5.jpg',
    '/images/6.jpg',
    '/images/7.jpg',
    '/images/8.jpg',
    '/images/9.jpg',
    '/images/10.jpg',
    '/images/11.jpg',
    '/images/12.jpg',
    '/images/13.jpg',
    '/images/14.jpg',
    '/images/15.jpg',
    '/images/16.jpg',
    '/images/17.jpg',
    '/images/18.jpg',
    '/images/19.jpg',
    '/images/20.jpg',
    '/images/21.jpg',
    '/images/22.jpg',
    '/images/23.jpg',
    '/images/24.jpg',
    '/images/25.jpg',
    '/images/26.jpg',
    '/images/27.jpg',
    '/images/28.jpg',
    '/images/29.jpg',
    '/images/30.jpg',
    '/images/31.jpg',
    '/images/32.jpg',
    '/images/33.jpg',
    '/images/34.jpg',
    '/images/35.jpg',
    '/images/36.jpg',
];

const HeroSection = () => {
    const [currentImage, setCurrentImage] = useState(0);

    const nextImage = () => {
        setCurrentImage((prevImage) =>
            prevImage === images.length - 1 ? 0 : prevImage + 1
        );
    };

    const prevImage = () => {
        setCurrentImage((prevImage) =>
            prevImage === 0 ? images.length - 1 : prevImage - 1
        );
    };

    return (
        <section id="home" className="flex flex-col items-center justify-center py-8 md:py-16 mt-20 md:mt-12 border-b dark:border-b-amber-50 dark:bg-gray-900">
            <div className="container mx-auto px-4">
                <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[75vh] overflow-hidden rounded-lg">
                    {/* Image Display */}
                    <img
                        src={images[currentImage]}
                        alt={`Slide ${currentImage + 1}`}
                        className="w-full h-full object-contain transition-all duration-500 ease-in-out"
                    />

                    {/* Navigation Buttons */}
                    <button
                        onClick={prevImage}
                        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-900 text-white dark:bg-white dark:text-black px-3 py-1 rounded-full shadow-md focus:outline-none"
                    >
                        ❮
                    </button>
                    <button
                        onClick={nextImage}
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-900 text-white dark:bg-white dark:text-black px-3 py-1 rounded-full shadow-md focus:outline-none"
                    >
                        ❯
                    </button>
                </div>

                {/* Responsive Pagination Dots */}
                <div className="flex justify-center mt-4">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentImage(index)}
                            className={`w-3 h-3 mx-1 rounded-full ${
                                currentImage === index ? 'bg-blue-950' : 'bg-gray-400'
                            } focus:outline-none`}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
