import { useState, useEffect } from 'react';

const logos = [
    'https://hmbrgr.co/wp-content/uploads/2024/05/hmbrgr-logo.svg',
    'https://cdn.prod.website-files.com/63eb4c3e1469066d649ad47e/63ef54d2abe86963a1770daf_LEMONDE-logo1.png',
    'https://www.bastas.com.tr/sites/default/files/styles/logo_150x108/public/site_logo/credentialing_providers/Bastas_Cement.png?itok=2ymf6uEy',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2FLwLmI7A_pvO7bAf2yvTjJH_kr9rGGb7Cg&s',
    'https://upload.wikimedia.org/wikipedia/en/9/95/Seal_of_the_Turkish_Armed_Forces.png',
    'https://www.atilim.edu.tr/assets/frontend/img/atilim-universitesi-logo-tr.svg'
];

const PartnerSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideCount = logos.length;
    const slidesToShow = 4; // Number of logos visible at once

    // Automatically move to the next slide every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slideCount);
        }, 3000);
        return () => clearInterval(interval);
    }, [slideCount]);

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slideCount - 1 : currentSlide - 1);
    };

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % slideCount);
    };

    // Calculate the offset based on the current slide
    const offset = -(currentSlide * (100 / slidesToShow));

    return (
        <section className="py-16 bg-gray-200 dark:bg-gray-900 border-t dark:border-t-amber-50 p-4">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold text-center mb-8">Çalıştığımız Firmalar</h2>
                <div className="relative overflow-hidden">
                    {/* Slider Container */}
                    <div
                        className="flex transition-transform duration-500 ease-in-out"
                        style={{
                            width: `${(slideCount / slidesToShow) * 100}%`,
                            transform: `translateX(${offset}%)`,
                        }}
                    >
                        {logos.map((logo, index) => (
                            <div
                                key={index}
                                className="w-1/4 p-4 flex justify-center items-center"
                                style={{ flex: `0 0 ${100 / slidesToShow}%` }}
                            >
                                <img
                                    src={logo}
                                    alt={`Company ${index + 1}`}
                                    className="max-w-[120px] max-h-[120px] object-contain mx-auto"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Previous and Next Buttons
                    <div className="flex justify-center mt-4 space-x-4 sm:absolute sm:top-1/2 sm:left-4 sm:transform sm:-translate-y-1/2 sm:flex sm:space-x-0 sm:space-y-0 sm:flex-col sm:right-4">

                    <button
                        onClick={prevSlide}
                        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-900 text-white dark:bg-white dark:text-black px-3 py-1 rounded-full shadow-md focus:outline-none"
                    >
                        ❮
                    </button>
                    <button
                        onClick={nextSlide}
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-900 text-white dark:bg-white dark:text-black px-3 py-1 rounded-full shadow-md focus:outline-none"
                    >
                        ❯
                    </button>
                    </div>*/}
                </div>
            </div>
        </section>
    );
};

export default PartnerSlider;
