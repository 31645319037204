const AboutSection = () => {
    return (
        <section id="about" className="py-20 bg-gray-100 px-4 dark:bg-gray-900 dark:text-white">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold">Hakkımızda</h2>
                <p className="mt-4 text-lg leading-relaxed">
                    Oğuzhan Havalandırma, endüstriyel ve ticari alanlarda havalandırma sistemlerinin imalat ve montajını
                    sağlayan
                    öncü bir firmadır. Müşterilerimize sağlıklı ve verimli hava akışı sağlayarak ortamların daha
                    konforlu olmasına yardımcı oluyoruz.
                </p>
                <p className="mt-4 text-lg leading-relaxed">
                    Firmamız, havalandırma kanallarının imalatını en yüksek kalite standartlarına uygun olarak
                    gerçekleştirir ve montaj aşamasında
                    profesyonel ekibimizle, yerinde doğru ve güvenilir kurulum hizmetleri sunar. Yılların verdiği
                    tecrübe ve uzmanlıkla,
                    işletmenizin ihtiyaçlarını tam olarak karşılayacak çözümler sunmaktayız.
                </p>
                <p className="mt-4 text-lg leading-relaxed">
                    Havalandırma projelerinizin her aşamasında, planlamadan montaj ve bakıma kadar her adımda
                    yanınızdayız.
                    Hedefimiz, enerji verimliliğini artırırken ortam havasının kalitesini iyileştirerek daha sağlıklı
                    yaşam ve çalışma koşulları sunmaktır.
                </p>
                <p className="mt-4 text-lg leading-relaxed">
                    Detaylı bilgi almak ve projelerinize özel çözümler için bizimle iletişime geçin.
                </p>
                <div className="mt-8">
                    <h3 className="text-2xl font-semibold">Şirketimizi Tanıyın</h3>
                    <div className="mt-4">
                        <video
                            className="w-full h-auto max-w-4xl mx-auto rounded-lg shadow-lg"
                            controls
                        >
                            <source src="/videos/1.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
