const ServicesSection = () => {
    return (
        <section id="services" className="py-20 bg-gray-100 dark:bg-gray-900 border-t dark:border-t-amber-50 p-4">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold text-center">Hizmetlerimiz</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
                    {/* Service 1 - Ventilation Manufacturing */}
                    <div className="p-6 bg-white rounded-lg shadow-lg">
                        <img
                            src="/images/24.jpg"
                            alt="Ventilation Manufacturing"
                            className="w-full h-48 object-cover rounded-lg"
                        />
                        <h3 className="text-2xl font-semibold mt-4 text-black">Havalandırma İmalatı</h3>
                        <p className="mt-2 text-gray-600">
                            Yüksek kaliteli malzemeler kullanarak endüstriyel ve ticari alanlara uygun havalandırma sistemlerinin imalatını yapıyoruz.
                            Müşterilerimize uzun ömürlü ve verimli çözümler sunuyoruz.
                        </p>
                    </div>

                    {/* Service 2 - Ventilation Installation */}
                    <div className="p-6 bg-white rounded-lg shadow-lg">
                        <img
                            src="/images/4.jpg"
                            alt="Ventilation Installation"
                            className="w-full h-48 object-cover rounded-lg"
                        />
                        <h3 className="text-2xl font-semibold mt-4 text-black">Havalandırma Montajı</h3>
                        <p className="mt-2 text-gray-600">
                            Profesyonel ekibimizle yerinde güvenilir montaj hizmeti sunuyoruz.
                            Hava kanallarının doğru bir şekilde yerleştirilmesiyle ortamınızın verimli hava akışını sağlıyoruz.
                        </p>
                    </div>

                    {/* Service 3 - Maintenance & Repair */}
                    <div className="p-6 bg-white rounded-lg shadow-lg">
                        <img
                            src="/images/11.jpg"
                            alt="Maintenance & Repair"
                            className="w-full h-48 object-cover rounded-lg"
                        />
                        <h3 className="text-2xl font-semibold mt-4 text-black">Bakım ve Onarım</h3>
                        <p className="mt-2 text-gray-600">
                            Havalandırma sistemlerinin düzenli bakımı ve onarımı, sistemlerin uzun ömürlü olmasını sağlar.
                            Müşterilerimize bakım ve onarım hizmetleri sunarak sürekli performans sağlıyoruz.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
