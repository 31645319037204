import {useEffect, useState} from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { HiMenu, HiX } from "react-icons/hi";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const savedDarkMode = localStorage.getItem('darkMode') === 'true';
        setDarkMode(savedDarkMode);

        if (savedDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, []);

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode); // Save preference to localStorage

        if (newDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    return (
        <header className="fixed top-0 left-0 w-full bg-white dark:bg-gray-800 shadow-md z-50">
            <nav className="container mx-auto flex justify-between items-center p-4">
                {/* Logo */}
                <div className="flex items-center space-x-2">
                    <img src={'/logo.webp'} alt="Oğuzhan Havalandırma" className="h-14 rounded-xl" />
                    <a href="/" className="flex flex-col text-xl font-mono font-bold">
                        <span className="text-blue-600">OĞUZHAN</span>
                        <span>HAVALANDIRMA</span>
                    </a>
                </div>
                {/* Desktop Menu */}
                <div className="hidden md:flex flex-grow justify-center">
                    <ul className="flex space-x-6">
                        <li><a href="" className="hover:text-blue-600">Ana Sayfa</a></li>
                        <li><a href="#about" className="hover:text-blue-600">Hakkımızda</a></li>
                        <li><a href="#services" className="hover:text-blue-600">Hizmetler</a></li>
                        <li><a href="#contact" className="hover:text-blue-600">İletişim</a></li>
                    </ul>
                </div>
                {/* Dark Mode Toggle Button */}
                <button
                    className="hidden md:flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 focus:outline-none"
                    onClick={toggleDarkMode}
                >
                    {darkMode ? <FaSun className="text-yellow-500" /> : <FaMoon className="text-gray-900" />}
                </button>
                {/* Mobile Menu Button */}
                <div className="md:hidden">
                    <button
                        className="text-gray-700 dark:text-white focus:outline-none"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? (
                            <HiX className="h-6 w-6" /> // Close (X) icon
                        ) : (
                            <HiMenu className="h-6 w-6" /> // Hamburger menu icon
                        )}
                    </button>
                </div>
            </nav>

            {/* Mobile Menu */}
            <div
                className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out transform ${isOpen ? "max-h-screen" : "max-h-0"}`}
            >
                <div className="bg-white dark:bg-gray-800">
                    {/* Menu Items */}
                    <ul className="flex flex-col items-center space-y-6 border-t p-4">
                        <li><a href="#about" className="hover:text-blue-600">Hakkımızda</a></li>
                        <li><a href="#services" className="hover:text-blue-600">Hizmetler</a></li>
                        <li><a href="#contact" className="hover:text-blue-600">İletişim</a></li>
                    </ul>
                    {/* Dark Mode Toggle Button for Mobile */}
                    <div className="flex justify-center p-4">
                        <button
                            className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 focus:outline-none"
                            onClick={toggleDarkMode}
                        >
                            {darkMode ? <FaSun className="text-yellow-500" /> : <FaMoon className="text-gray-900" />}
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
