import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import ServicesSection from "./components/ServicesSection";
import ContactSection from "./components/ContactSection";
import Footer from "./components/Footer";
import PartnerSlider from "./components/PartnerSlider";

function App() {
  return (
      <div className="min-h-screen dark:bg-gray-900 dark:text-white transition-colors duration-300 font-mono">
          <Header/>
          <HeroSection/>
          <AboutSection/>
          <ServicesSection/>
          <PartnerSlider/>
          <ContactSection/>
          <Footer/>
      </div>
  );
}

export default App;
